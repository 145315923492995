import { useMemo } from "react";

import { get } from "lodash";
import PropTypes from "prop-types";
import { Col } from "react-bootstrap";
import { connect } from "react-redux";
import { Route, Switch } from "react-router";
import ReactRouterPropTypes from "react-router-prop-types";
import {
  compose,
  lifecycle,
  withHandlers,
  withProps,
  withState,
} from "recompose";

import { withAppLoader, withNotifier } from "@dpdgroupuk/mydpd-app";
import { APP_ROLES } from "@dpdgroupuk/mydpd-enums";
import {
  Card,
  Main,
  Tile,
  useMyDpdTheme,
  withOverlay,
  withPrompt,
  withSnackbar,
} from "@dpdgroupuk/mydpd-ui";
import {
  trackProps,
  withTrack,
  withTracker,
  withTrackProps,
} from "@dpdgroupuk/react-event-tracker";
import { PARCEL_ACTION_CODE } from "@dpdgroupuk/redback-enums";

import ParcelStatusProvider from "../../components/AuthUser/ParcelStatusProvider";
import CardWithTitle from "../../components/CardWithTitle";
import WarningModal from "../../components/WarningModal";
import { GENERAL_VIEW, WATCH_LIST_MODAL } from "../../constants/analytics";
import * as M from "../../constants/strings";
import withAppRolesValidator from "../../HOCS/withAppRolesValidator";
import withLoaderHandlers from "../../HOCS/withLoaderHandlers";
import { DASHBOARD, DELIVERIES } from "../../router";
import ProtectedRoute from "../../router/ProtectedRoute";
import {
  fetchNeighbourAddresses,
  fetchPickupLocationShop,
} from "../EditDelivery/actions";
import EditDelivery from "../EditDelivery/EditDeliveryPage";
import {
  addToWatchList,
  clearParcelData,
  fetchParcel,
  fetchParcelActions,
  removeFromWatchList,
} from "./actions";
import HighValuePinBanner from "./components/HighValuePinBanner";
import { getParcelComponent, transformActions } from "./model";
import styles from "./ParcelPage.module.scss";
import {
  getParcel,
  getParcelActions,
  isActionsAvailable,
  isRelatedParcelsAvailable,
} from "./selectors";
import { ChatBotApp } from "@dpdgroupuk/mydpd-chat";

const ParcelPage = ({
  parcel,
  actions,
  onOptionClick,
  match,
  reloadFn,
  hasAppRoles,
  showWarningModal,
  toggleWarningModal,
  warningModalMessage,
  isActionsAvailable,
  ...rest
}) => {
  const theme = useMyDpdTheme();
  const canEdit = hasAppRoles(APP_ROLES.CHANGE_DELIVERY);

  const ParcelComponent = useMemo(
    () => getParcelComponent(parcel.parcelStatusType),
    [parcel]
  );

  const redirectUrl = match.params?.parcelCode
    ? `${DELIVERIES}/${match.params?.parcelCode}`
    : DASHBOARD;

  return (
    <Switch>
      <ProtectedRoute
        appRoles={APP_ROLES.CHANGE_DELIVERY}
        redirectUrl={redirectUrl}
        exact
        match={match}
        path={`${match.path}/:actionCode`}
      >
        <EditDelivery
          {...rest}
          actions={actions}
          parcel={parcel}
          reloadParcelFn={reloadFn}
          redirectUrl={redirectUrl}
        />
      </ProtectedRoute>
      <Route exact path={match.path}>
        <ParcelStatusProvider parcelStatus={parcel.parcelStatusHtml}>
          <Main body>
            <Card.Stack fluid>
              <ParcelComponent parcel={parcel} match={match} {...rest}>
                {canEdit && (
                  <Col sm={12}>
                    <CardWithTitle title={M.AVAILABLE_OPTIONS}>
                      {parcel.parcelStatusInfo && (
                        <HighValuePinBanner
                          parcelStatusInfo={parcel.parcelStatusInfo}
                        />
                      )}
                      {!isActionsAvailable ? (
                        <span className={styles.noActionsText}>
                          {M.NO_ACTIONS_AVAILABLE}
                        </span>
                      ) : (
                        <Tile.Stack className="p-0 m-0">
                          {transformActions(actions).map(
                            ({ title, icon: Icon, action, disabled }) => (
                              <Tile
                                key={title}
                                title={title}
                                disabled={disabled}
                                onClick={() => onOptionClick({ action })}
                              >
                                <Icon alt={title} fill={theme.palette.brand} />
                              </Tile>
                            )
                          )}
                        </Tile.Stack>
                      )}
                    </CardWithTitle>
                  </Col>
                )}
              </ParcelComponent>
              <WarningModal
                show={showWarningModal}
                close={() => {
                  toggleWarningModal(false);
                }}
                message={warningModalMessage}
              />
            </Card.Stack>
          </Main>
          <ChatBotApp />
        </ParcelStatusProvider>
      </Route>
    </Switch>
  );
};

ParcelPage.propTypes = {
  parcel: PropTypes.object,
  actions: PropTypes.object,
  onOptionClick: PropTypes.any,
  reloadFn: PropTypes.func,
  hasAppRoles: PropTypes.func,
  ...ReactRouterPropTypes,
};

export default compose(
  withSnackbar,
  connect(),
  connect(
    state => ({
      isActionsAvailable: isActionsAvailable(state),
      isRelatedParcelsAvailable: isRelatedParcelsAvailable(state),
    }),
    () => ({
      onConfirmAddToWatchList: addToWatchList,
      onConfirmRemoveFromWatchList: removeFromWatchList,
      clearParcelData,
    })
  ),
  withLoaderHandlers,
  withState("showWarningModal", "toggleWarningModal", false),
  withState("warningModalMessage", "setWarningModalMessage", ""),
  withAppLoader({
    shouldReload: (prevProps, nextProps) =>
      prevProps.match.params.parcelCode !== nextProps.match.params.parcelCode,
    loadFn: ({ dispatch, match }, fetchOptions) =>
      Promise.all([
        dispatch(fetchParcel(match.params.parcelCode, fetchOptions)),
        dispatch(fetchParcelActions(match.params.parcelCode, fetchOptions)),
      ]),
  }),
  withOverlay,
  withPrompt,
  withNotifier,
  withProps(({ prompt, dispatch, snackbar }) => ({
    watchListHandler: (
      parcelCode,
      { header, message, confirmButtonText, onClickConfirm, onClickReject }
    ) => {
      prompt.showConfirmation({
        header,
        message,
        confirmButtonText,
        closeButtonText: M.CANCEL,
        size: "md",
        onConfirm: () =>
          dispatch(onClickConfirm(parcelCode))
            .then(() => dispatch(fetchParcelActions(parcelCode)))
            .catch(err => {
              const error = get(
                err,
                "errors[0].message",
                M.ACTION_CAN_NOT_BE_COMPLETED
              );
              snackbar.showAlert({
                message: error,
              });
            }),
        onReject: onClickReject,
        withIcon: false,
        className: styles.modal,
        contentClassName: styles.modalContent,
      });
    },
  })),
  withTrack(trackProps(GENERAL_VIEW)),
  withTrackProps({
    onConfirmAddToWatchList: WATCH_LIST_MODAL.SUBMIT_ADD_TO_WATCHLIST,
    onConfirmRemoveFromWatchList: WATCH_LIST_MODAL.SUBMIT_REMOVE_FROM_WATCHLIST,
    onRejectAddToWatchList: WATCH_LIST_MODAL.REJECT_ADD_TO_WATCHLIST,
    onRejectRemoveFromWatchList: WATCH_LIST_MODAL.REJECT_REMOVE_FROM_WATCHLIST,
    onClickLoad: GENERAL_VIEW.LOAD,
    onClickDeliveryHistory: GENERAL_VIEW.DELIVERY_HISTORY,
    onClickConsignment: GENERAL_VIEW.CONSIGNMENT,
    onClickAdditionalInformation: GENERAL_VIEW.ADDITIONAL_INFORMATION,
  }),
  connect(state => ({
    parcel: getParcel(state),
    actions: getParcelActions(state),
  })),
  withHandlers(
    ({
      history,
      match,
      watchListHandler,
      onConfirmAddToWatchList,
      onConfirmRemoveFromWatchList,
      onRejectAddToWatchList,
      onRejectRemoveFromWatchList,
      dispatch,
      toggleWarningModal,
      setWarningModalMessage,
      overlay,
      notifier,
      parcel,
    }) => ({
      onOptionClick: () =>
        notifier.runAsync(async option => {
          switch (option.action) {
            case PARCEL_ACTION_CODE.DTN: {
              overlay.show();
              const neighbours = await dispatch(
                fetchNeighbourAddresses(parcel.deliveryDetails.address)
              );
              if (neighbours.data.length === 1) {
                setWarningModalMessage(M.NO_NEIGHBOURS_FOR_POSTCODE);
                toggleWarningModal(true);
              } else {
                history.push(
                  `/parcels/${match.params.parcelCode}/${option.action}`
                );
              }
              overlay.hide();
              break;
            }
            case PARCEL_ACTION_CODE.PKU: {
              overlay.show();
              const results = await dispatch(
                fetchPickupLocationShop(match.params.parcelCode)
              );
              if (!results.data.length) {
                setWarningModalMessage(M.NO_PICKUP_SHOPS_FOR_POSTCODE);
                toggleWarningModal(true);
              } else {
                history.push(
                  `/parcels/${match.params.parcelCode}/${option.action}`
                );
              }
              overlay.hide();
              break;
            }
            case PARCEL_ACTION_CODE.WTC:
              watchListHandler(match.params.parcelCode, {
                header: M.WATCH_LIST_ADD,
                message: M.WATCH_LIST_ADD_TEXT,
                confirmButtonText: M.WATCH_LIST_ADD_BTN,
                onClickConfirm: onConfirmAddToWatchList,
                onClickReject: onRejectAddToWatchList,
              });
              break;
            case PARCEL_ACTION_CODE.WTR:
              watchListHandler(match.params.parcelCode, {
                header: M.WATCH_LIST_REMOVE,
                message: M.WATCH_LIST_REMOVE_TEXT,
                confirmButtonText: M.WATCH_LIST_REMOVE_BTN,
                onClickConfirm: onConfirmRemoveFromWatchList,
                onClickReject: onRejectRemoveFromWatchList,
              });
              break;
            default:
              history.push(
                `/parcels/${match.params.parcelCode}/${option.action}`
              );
              break;
          }
        }),
      onClickConsignment: () => (e, item) => {
        const parcelCode = get(item, "original.parcelCode");
        if (parcelCode) {
          window.open(`${DELIVERIES}/${parcelCode}`, "_blank")?.focus();
        }
      },
    })
  ),
  withTracker,
  withAppRolesValidator,
  lifecycle({
    componentDidMount() {
      this.props.tracker.setContext({
        parcelCode: this.props.match.params.parcelCode,
      });
    },
    componentDidUpdate(prevProps) {
      prevProps.match.params.parcelCode !==
        this.props.match.params.parcelCode && this.props.reloadFn(false);
      // there is 'false' if you want to reload page with a spinner
    },
    componentWillUnmount() {
      const { dispatch, clearParcelData } = this.props;
      dispatch(clearParcelData);
    },
  })
)(ParcelPage);
