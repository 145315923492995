import { get, head, isEmpty } from "lodash";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { compose, lifecycle, withState } from "recompose";

import {
  withAppLoader,
  withAppUserPreferences,
  withNotifier,
} from "@dpdgroupuk/mydpd-app";
import {
  withOverlay,
  withPrompt,
  withSnackbar,
  Banner,
} from "@dpdgroupuk/mydpd-ui";
import { BANNERS_TYPES } from "@dpdgroupuk/mydpd-enums";

import { Fields, FilterFields } from "../../constants/forms";
import * as M from "../../constants/strings";
import withOnLoadFailure from "../../HOCS/withOnLoadFailure";
import withReportFeedbackHandler from "../../HOCS/withReportFeedbackHandler";
import { prepareFilters } from "../../models/dashboard";
import {
  getFindByCode,
  getTotalParcelsCount,
  getViewParcels,
} from "../../redux/deliveries/selectors";
import { ReferenceActions } from "../../redux/references";
import {
  getCountries,
  getDepot,
  getProducts,
  getServices,
} from "../../redux/references/selectors";
import { DELIVERIES } from "../../router";
import { omitNilValues } from "../../utils/object";
import {
  createLocationState,
  getFiltersQuery,
  getLocationState,
  getQueryPagination,
  getSearchQuery,
  parseSortByValue,
  stringifyQuery,
} from "../../utils/query";
import { mapCountries, mapDepot, mapForDropdown } from "../../utils/reference";

export default compose(
  withRouter,
  withReportFeedbackHandler,
  withOnLoadFailure,
  withSnackbar,
  withState("bannerId", "setBannerId", null),
  withAppLoader({
    loadFn: async (
      {
        findParcels,
        fetchParcels,
        filterParcels,
        location,
        history,
        searchFilterMap,
      },
      fetchOptions
    ) => {
      let state = getLocationState(location);
      const newState = {};
      const page = getQueryPagination(location);
      if (!state.searchFindByCode) {
        const query = getSearchQuery(location);
        const { findByCode, expiresIn } = await findParcels(
          query,
          fetchOptions
        );
        if (findByCode) {
          newState.searchFindByCode = findByCode;
          newState.expiresIn = expiresIn;
        }
      }
      if (!state.filterFindByCode) {
        const filters = getFiltersQuery(location, searchFilterMap);
        if (!isEmpty(filters)) {
          const sortFilters = parseSortByValue(filters);
          const { findByCode } = await filterParcels(
            newState.searchFindByCode || state.searchFindByCode,
            { ...prepareFilters(filters), ...sortFilters },
            fetchOptions
          );
          newState.filterFindByCode = findByCode;
        }
      }
      state = createLocationState({
        ...state,
        ...newState,
      });
      if (!isEmpty(newState)) {
        history.replace({
          search: location.search,
          state,
        });
      }
      if (state.filterFindByCode || state.searchFindByCode) {
        return fetchParcels(
          state.filterFindByCode || state.searchFindByCode,
          page,
          fetchOptions
        );
      }
    },
  }),
  withPrompt,
  withOverlay,
  Banner.withBanner,
  withNotifier,
  withAppUserPreferences,
  connect(
    state => ({
      totalCount: getTotalParcelsCount(state),
      parcels: getViewParcels(state),
      findByCode: getFindByCode(state),
      countries: mapCountries(getCountries(state)),
      products: mapForDropdown(getProducts(state)),
      services: mapForDropdown(getServices(state)),
      depots: mapDepot(getDepot(state)),
    }),
    (
      dispatch,
      {
        history,
        location,
        overlay,
        notifier,
        resetFilters,
        filterParcels,
        findParcels,
      }
    ) => {
      const onNextOrPrev = targetPage => {
        const query = getSearchQuery(location);
        const filters = getFiltersQuery(location);

        history.push({
          search: stringifyQuery({
            ...query,
            ...filters,
            page: targetPage,
          }),
          state: getLocationState(location),
        });
      };

      return {
        onFilter: notifier.runAsync(async values => {
          try {
            overlay.show();
            const query = getSearchQuery(location);
            const state = getLocationState(location);

            if (!state.searchFindByCode) {
              const result = await findParcels(query);
              state.searchFindByCode = result.findByCode;
              state.filterFindByCode = result.findByCode; // reset filter to search state
              state.expiresIn = result.expiresIn;
            }

            if (!isEmpty(values)) {
              const sortFilters = parseSortByValue(values);
              const { findByCode } = await filterParcels(
                state.searchFindByCode,
                omitNilValues({
                  ...prepareFilters(values),
                  ...sortFilters,
                })
              );
              state.filterFindByCode = findByCode;
            } else {
              state.filterFindByCode = null;
            }

            overlay.hide();

            history.push({
              search: stringifyQuery({
                ...query,
                ...values,
              }),
              state: createLocationState(state),
            });
          } finally {
            overlay.hide();
          }
        }),
        onNext: onNextOrPrev,
        onPrevious: onNextOrPrev,
        onFirst: onNextOrPrev,
        onLast: onNextOrPrev,
        onReset: notifier.runAsync(async () => {
          resetFilters();
          const query = getSearchQuery(location);
          const state = getLocationState(location);
          state.filterFindByCode = null;
          if (!state.searchFindByCode) {
            overlay.show();
            const { findByCode, expiresIn } = await findParcels(query);
            state.expiresIn = expiresIn;
            state.searchFindByCode = findByCode;
          }
          overlay.hide();

          history.push({
            search: stringifyQuery(query),
            state: createLocationState(state),
          });
        }),
        onClickRow: (e, { original }) =>
          history.push(`${DELIVERIES}/${original.parcelCode}`),

        fetchReferences: () => dispatch(ReferenceActions.fetchReferences()),

        fetchRanges: accountCode =>
          dispatch(ReferenceActions.fetchRanges(accountCode)),

        fetchException: () => dispatch(ReferenceActions.fetchException()),
      };
    }
  ),
  connect(
    null,
    (
      _,
      {
        bannerId,
        setBannerId,
        banner,
        history,
        findByCode,
        overlay,
        location,
        createExporterParcelAction,
      }
    ) => ({
      onExportPress: async () => {
        overlay.show();

        const searchQuery = getSearchQuery(location);
        const filtersQuery = getFiltersQuery(location);

        bannerId && banner.hideById(bannerId);

        try {
          await createExporterParcelAction({
            findByCode,
            ...searchQuery,
            ...filtersQuery,
          });

          const bannerId = banner.showByType(BANNERS_TYPES.INFO, {
            message: M.EXPORT_STARTED_PLEASE_USE,
            closable: true,
            showIcon: true,
            actions: [
              {
                text: "CLICK HERE",
                handleClick: () => {
                  banner.removeById(bannerId);
                  history.push(`/export`);
                },
              },
            ],
          });
          setBannerId(bannerId);
        } catch (err) {
          const bannerId = banner.showByType(BANNERS_TYPES.WARNING, {
            message: M.EXPORT_IS_ALREADY_IN_PROGRESS_PLEASE_USE,
            closable: true,
            showIcon: true,
            actions: [
              {
                text: "CLICK HERE",
                handleClick: () => {
                  banner.removeById(bannerId);
                  history.push(`/export`);
                },
              },
            ],
          });
          setBannerId(bannerId);
        } finally {
          overlay.hide();
        }
      },
    })
  ),
  lifecycle({
    componentDidMount() {
      const {
        location,
        fetchRanges,
        fetchReferences,
        fetchException,
        parcels,
        history,
        totalCount,
        customers,
      } = this.props;
      const searchQuery = getSearchQuery(location);
      const filtersQuery = getFiltersQuery(location);
      let account =
        get(searchQuery, Fields.ACCOUNT) ||
        get(filtersQuery, FilterFields.ACCOUNT_CODE);

      if (customers?.length === 1) {
        account = head(customers).account;
      }

      fetchReferences();
      fetchException();

      if (account) {
        fetchRanges(account);
      }
      if (parcels.length === 1 && totalCount === 1 && !filtersQuery) {
        history.push(`${DELIVERIES}/${parcels[0].parcelCode}`);
      }
    },
    componentDidUpdate(prevProps) {
      const { key, state } = this.props.location;
      const { key: prevKey, state: prevState } = prevProps.location;
      if (
        prevKey !== key ||
        JSON.stringify(prevState) !== JSON.stringify(state)
      ) {
        this.props.overlay.show();
        const { reloadFn } = this.props;
        reloadFn(true).finally(this.props.overlay.hide);
      }
    },
    componentWillUnmount() {
      this.props.clearFindByCodeCache();
    },
  })
);
